import { FC, ReactNode, useState } from "react";
import { Channel, Track } from "./types";
import { IVideoContext, VideoContext } from "./VideoContext";

interface VideoProvider {
  children: ReactNode;
}

export const VideoProvider: FC<VideoProvider> = ({ children }) => {
  const [list, setList] = useState<Track[]>([]);
  const [listLoaded, setListLoaded] = useState<boolean>(false);
  const [groupsLoaded, setGroupsLoaded] = useState<boolean>(false);
  const [groups, setGroups] = useState<{ [key: string]: Channel }>({});
  const [videos, setVideos] = useState<{ tracks: Track[] }>({ tracks: [] });
  const [filePath, setFilePath] = useState<string>("");
  const [loadingVideo, setLoadingVideo] = useState<boolean>(false);
  const [channelSelected, setChannelSelected] = useState<string>("");
  const [videoSelected, setVideoSelected] = useState<string>("");

  const initialState: IVideoContext = {
    list,
    setList,
    listLoaded,
    setListLoaded,
    groupsLoaded,
    setGroupsLoaded,
    groups,
    setGroups,
    videos,
    setVideos,
    filePath,
    setFilePath,
    loadingVideo,
    setLoadingVideo,
    channelSelected,
    setChannelSelected,
    videoSelected,
    setVideoSelected,
  };

  return (
    <VideoContext.Provider value={{ ...initialState }}>
      {children}
    </VideoContext.Provider>
  );
};
