import React, { type FC, useContext } from "react";
import { Card } from "../Card";
import { Track } from "../types";
import { VideoContext } from "../VideoContext";
import "./styles.css";

interface VideosProps {
  items: Track[];
}

export const Videos: FC<VideosProps> = (props) => {
  const { items } = props;

  const { setFilePath, setLoadingVideo, setVideoSelected } =
    useContext(VideoContext);

  const onClickHandler = (file: string, title: string) => {
    setFilePath("");
    setVideoSelected(title);
    setTimeout(() => setFilePath(file), 500);
  };

  return (
    <div id="Videos">
      {items.map(({ file, title }) => (
        <Card key={file} onClick={() => onClickHandler(file, title)}>
          <h2>{title.toLowerCase()}</h2>
        </Card>
      ))}
    </div>
  );
};
