import React from "react";

export const LoadingButton = () => {
  return (
    <button className="loading-button">
      <div className="loading-circles">
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
      </div>
    </button>
  );
};
