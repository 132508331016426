import { createRef, EventHandler, FC, ReactEventHandler } from "react";
import ReactHlsPlayer, { HlsMimeType } from "react-hls-player/dist";
import { Video } from "./types";
import "./VideoPlayer/VideoPlayer.css";
import * as Sentry from "@sentry/react";

const getFileExtension = (filename: string): null | string => {
  const match = filename.match(/\.(\w+)$/);

  if (!match) {
    return null;
  }

  return match[1];
};

function modifyURL(url: string) {
  const fileExtension = getFileExtension(url);

  if (!fileExtension || fileExtension === "m3u8") {
    const clearUrl = url.replace(".m3u8", "");
    const parsedUrl = clearUrl.replace(
      /(http:\/\/[^\/:]+(:\d+)?)(\/)/,
      "$1/live$3",
    );
    return `${parsedUrl}.m3u8`;
  }

  return url;
}

const getMediaType = (mediaExtension: string | null): HlsMimeType => {
  if (!mediaExtension) {
    return HlsMimeType.M3U8;
  }

  switch (mediaExtension) {
    case "m3u8":
      return HlsMimeType.M3U8;
    case "mp4":
      return HlsMimeType.MP4;
    default:
      return HlsMimeType.M3U8;
  }
};
export const VideoPlayer: FC<Video> = ({ source }) => {
  const playerRef = createRef<HTMLVideoElement>();

  const fileExtension = getFileExtension(source);
  const mediaType = getMediaType(fileExtension);

  //@ts-ignore
  const handleError = (error) => {
    Sentry.setContext("track", {
      source,
      modifiedUrl: modifyURL(source),
    });
    Sentry.captureException(new Error(error));
  };

  return (
    <div className="VideoPlayer">
      {mediaType === HlsMimeType.MP4 ? (
        <video autoPlay controls playsInline height="auto" width="100%">
          <source src={modifyURL(source)} type={mediaType} />
        </video>
      ) : (
        <ReactHlsPlayer
          playerRef={playerRef}
          autoPlay={true}
          src={modifyURL(source)}
          width="100%"
          height="auto"
          controls={true}
          playsInline={true}
          type={mediaType}
          onError={handleError}
        />
      )}
    </div>
  );
};
