import { useContext, useEffect, useState } from "react";
import { Channels } from "./Channels";
import "./Sidebar.css";
import { Track } from "./types";
import { VideoContext } from "./VideoContext";
import { Videos } from "./Videos";

export const Sidebar = () => {
  const { channelSelected, list } = useContext(VideoContext);
  const [tracks, setTracks] = useState<Track[]>([]);

  const getVideos = (channel: string) => {
    if (channel) {
      const tracks = list.filter((track) => {
        return track.params["group-title"] === channel;
      });
      setTracks([...tracks]);
    }
  };

  useEffect(() => {
    getVideos(channelSelected);
  }, [channelSelected]);

  return (
    <div id="Sidebar">
      {!channelSelected && <Channels />}
      {channelSelected && <Videos items={tracks} />}
    </div>
  );
};
