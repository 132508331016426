import {
  useContext,
  useEffect,
  useState,
  type ChangeEvent,
  type MouseEvent,
} from "react";
//@ts-ignore
import { parse } from "./../utils/m3u.js";
import { LOCALSTORAGE_M3ULIST} from "../../constants";
import "./style.css";
import { VideoContext } from "../VideoContext";
import { saveListUrl } from "./utils/saveListUrl";
import { fetchList } from "./utils/fetchList";
import { LoadingButton } from "./LoadingButton";

export const Topbar = () => {
  const [formValue, setFormValue] = useState("");
  const [loading, setLoading] = useState(false);
  const {
    channelSelected,
    listLoaded,
    setChannelSelected,
    setFilePath,
    setList,
    setListLoaded,
    setVideos,
    setVideoSelected,
    videoSelected,
  } = useContext(VideoContext);

  const handleOnChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target;
    setFormValue(value);
  };

  const handleOnClick = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (!formValue) {
      return;
    }

    saveListUrl(formValue);
    setLoading(true);

    const data = await fetchList(formValue);

    if (data) {
      const { tracks } = parse(data);
      setList(tracks);
      setListLoaded(true);
    }
  };

  const reset = () => {
    setVideos({ tracks: [] });
    setChannelSelected("");
    setVideoSelected("");
    setFilePath("");
  };

  useEffect(() => {
    const list = localStorage.getItem(LOCALSTORAGE_M3ULIST);
    if (list) {
      setFormValue(list);
    }
  }, []);

  return (
    <div id="Topbar">
      <div id="Breadcrumb">
        <ul>
          <li className="start" onClick={reset}>
            Início
          </li>
          {channelSelected && <li>{channelSelected}</li>}
          {videoSelected && <li>{videoSelected}</li>}
        </ul>
      </div>
      <div id="FormList">
        <input
          placeholder="insert your m3u url..."
          onChange={handleOnChange}
          type="text"
          defaultValue={formValue}
        />
        {loading && !listLoaded ? (
          <LoadingButton />
        ) : (
          <button onClick={handleOnClick}>ok</button>
        )}
      </div>
    </div>
  );
};
