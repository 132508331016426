import * as Sentry from "@sentry/react";
import { API, HEADERS, METHODS } from "../../../constants";

export const fetchList = async (listUrl: string) => {
  if (!listUrl) {
    return;
  }

  const headers = {
    method: METHODS.POST,
    body: JSON.stringify({ listUrl }),
    headers: {
      [HEADERS.CONTENT_TYPE]: HEADERS.JSON,
    },
  };

  try {
    const response = await fetch(`${API}/list`, headers);

    if (!response.ok) {
      const error = `Unable to fetch list, error: ${response?.status}`;

      Sentry.setContext("list_url", { listUrl });
      Sentry.captureException(error);

      throw new Error(error);
    }

    const data = await response.text();

    return data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};
