import React, {
  FC,
  useContext,
  useEffect,
  useState,
  type MouseEvent,
} from "react";
import { Card } from "../Card";
import { Track, type Channel } from "../types";
import { VideoContext } from "../VideoContext";
import "./style.css";

type Channels = { channels: Channel[] } | undefined;

const Li: FC<Channel> = ({ logo, title }) => {
  const { setChannelSelected, setVideos } = useContext(VideoContext);
  let channelLabel = title;

  const onClickHandler = async (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    const {
      currentTarget: { innerText: title },
    } = event;

    setChannelSelected(title);
  };

  return (
    <Card>
      <>
        <div id="Avatar">
          <img src={logo} />
        </div>
        <h1>
          <a href={`#${title}`} onClick={onClickHandler}>
            {channelLabel}
          </a>
        </h1>
      </>
    </Card>
  );
};

const buildGroups = async (tracks: Track[]) => {
  const groups = new Map();

  tracks.forEach((track: Track) => {
    const hasParams = Object.keys(track.params).length;
    const title = hasParams ? track.params["group-title"].trim() : track.title;
    groups.set(title, {
      logo: hasParams ? track.params["tvg-logo"] : "",
      title,
    });
  });

  return groups;
};

const transformGroups = (groupsMap: Map<string, Channel>) => {
  const tmp: Channel[] = [];
  groupsMap.forEach((value, key) => {
    tmp.push({
      title: key.trim(),
      logo: value.logo,
    });
  });
  return tmp;
};

const setGroups = async (list: Track[]) => {
  const groups = await buildGroups(list);
  const transformedGroups = transformGroups(groups);

  return transformedGroups;
};

export const Channels = () => {
  const { list } = useContext(VideoContext);
  const [channels, setChannels] = useState<Channel[]>([]);

  useEffect(() => {
    if (list.length) {
      setGroups(list).then((data) => {
        setChannels([...data]);
      });
    }
  }, [list]);

  const renderLi = (channels: Channel[]) => {
    return channels.map((channel) => {
      return <Li key={channel.title} {...channel} />;
    });
  };

  return <div id="Channels">{renderLi(channels)}</div>;
};
