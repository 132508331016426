import { useContext, useEffect, useState } from "react";
import "./App.css";
import { Sidebar } from "./Sidebar";
import { VideoContext } from "./VideoContext";
import { Topbar } from "./Topbar";
import { Ribbon } from "./Ribbon";
import { VideoPlayer } from "./VideoPlayer";
import { Content } from "./Content";

function App() {
  const [track, setTrack] = useState<{
    filePath: string;
    fileExtension: string | null;
  }>();
  const { filePath } = useContext(VideoContext);

  const getFileExtension = (filename: string) => {
    const match = filename.match(/\.(\w+)$/);
    return match ? `video/${match[1]}` : null;
  };

  const watchVideoHandler = (filepath: string | null) => {
    if (!filepath) {
      return;
    }
    const fileExtension = getFileExtension(filepath);
    setTrack({ filePath, fileExtension });
  };

  useEffect(() => {
    watchVideoHandler(filePath);
  }, [filePath]);

  return (
    <div className="App">
      <Ribbon />
      <Topbar />
      <Content>
        <>
          {!filePath && <Sidebar />}
          {filePath && <VideoPlayer source={filePath} />}
        </>
      </Content>
    </div>
  );
}

export default App;
