export const API: string = import.meta.env.VITE_BASE_API_URL

export const MIME_TYPES = {
  MP2T: "video/mp2t",
} as const;

export const LOCALSTORAGE_M3ULIST: string = "m3ulist";
export const LOCALSTORAGE_M3UURLKEY: string = "m3uurlkey";
export const LOCALSTORAGE_M3UGROUPS: string = "m3ugroups";

export const METHODS = {
  POST: "POST"
} as const;


export const HEADERS = {
  CONTENT_TYPE: "Content-Type",
  JSON: "application/json",
  VERCEL_PROTECTION_BYPASS: "x-vercel-protection-bypass",
  VERCEL_PROTECTION_TOKEN: import.meta.env.VITE_VERCEL_PROTECTION_TOKEN
}
