import React, { FC } from "react";
import "./styles.css";

interface CardsProps {
  children: JSX.Element
  onClick?: () => void
}

export const Card: FC<CardsProps> = (props) => {
  const { children, onClick } = props;
  return <div id="Card" onClick={onClick}>{children}</div>;
};
