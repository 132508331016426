import React, { FC } from "react";
import "./styles.css"

interface ContentProps {
  children: JSX.Element;
}
export const Content: FC<ContentProps> = (props) => {
  const { children } = props;

  return <div id="Content">{children}</div>;
};
