import "./Ribbon.css"

export const Ribbon = () => {
  return (
    <p id="Ribbon">
      Warning: we aren't saving any data provided by you, in case of something
      stop working, refresh the page and insert your list again
    </p>
  );
};
