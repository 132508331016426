import { createContext, Dispatch, SetStateAction } from "react";
import { Channel, Track } from "./types";

export interface IVideoContext {
  list: Track[];
  setList: Dispatch<SetStateAction<Track[]>>;
  listLoaded: boolean;
  setListLoaded: Dispatch<SetStateAction<boolean>>;
  groupsLoaded: boolean;
  setGroupsLoaded: Dispatch<SetStateAction<boolean>>;
  groups: { [key: string]: Channel };
  setGroups: Dispatch<SetStateAction<{ [key: string]: Channel }>>;
  videos: { tracks: Track[] };
  setVideos: Dispatch<SetStateAction<{ tracks: Track[] }>>;
  filePath: string;
  setFilePath: Dispatch<SetStateAction<string>>;
  loadingVideo: boolean;
  setLoadingVideo: Dispatch<SetStateAction<boolean>>;
  channelSelected: string;
  setChannelSelected: Dispatch<SetStateAction<string>>;
  videoSelected: string;
  setVideoSelected: Dispatch<SetStateAction<string>>;
}

const initialState: IVideoContext = {
  list: [],
  setList: () => undefined,
  listLoaded: false,
  setListLoaded: () => false,
  groupsLoaded: false,
  setGroupsLoaded: () => undefined,
  groups: {},
  setGroups: () => undefined,
  videos: { tracks: [] },
  setVideos: () => undefined,
  filePath: "",
  setFilePath: () => undefined,
  loadingVideo: false,
  setLoadingVideo: () => false,
  channelSelected: "",
  setChannelSelected: () => false,
  videoSelected: "",
  setVideoSelected: () => false,
};

export const VideoContext = createContext<IVideoContext>(initialState);
